import * as React from "react";
import DemoButtons from "./DemoButtons";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Label } from "@fluentui/react";
import { Link } from "react-router-dom";
// import {
//   AiOutlineInstagram,
//   AiOutlineFacebook,
//   AiOutlineTwitter,
//   AiOutlineLinkedin,
//   AiOutlineYoutube,
// } from "react-icons/ai";
import Civicprivacy from "./Civicprivacy";
import CivicTermCondition from "./CivicTermCondition";
import Aboutus from "./Aboutus";
import { Privacypolicy } from "./Privacypolicy";
import { TermandCondition } from "./TermandCondition";
import Civic365 from "./Civic365";
import { Civicaboutus } from "./Civicaboutus";
import { Helpdeskhome } from "./Helpdeskhome";
import ActualAboutUs from "./ActualAboutUs";
import TalentManagement from "./TalentManagement";

function Civic365Home() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Civic365/>,
  },
  {
    path: "/aboutus",
    element: <ActualAboutUs page={"civic365"}/>
    },
    {
      path: "/about-us",
      element: <Civicaboutus/>
},
  {
      path: "/privacy-policy",
      element: <Civicprivacy/>
  }, 
  {
      path: "/terms-and-conditions",
      element: <CivicTermCondition/>
  },
  {
    path: "/helpdesk",
    element: <Helpdeskhome/>
  },
  {
    path: "/helpdesk/aboutus",
    element: <ActualAboutUs page={"helpdesk365"} />
    },
    {
      path: "/helpdesk/about-us",
      element: <Aboutus/>
    },
  {
    path: "/helpdesk/privacy-policy",
    element: <Privacypolicy/>
  },
  {
    path: "/helpdesk/terms-and-conditions",
    element: <TermandCondition/>
  },
  {
    path: "/talent-Management",
    element: <TalentManagement/>
  }
   
  ]);

  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      
      <RouterProvider router={router} />
    </>
  );
}
export default Civic365Home;
