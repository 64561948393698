import * as React from "react";
import Logo from "../Assests/Images/HR365Logo.png";
import DemoButtons from "./DemoButtons";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import './talent.css'
function Header() {
  const [ham, setHam] = React.useState(false);
  return (
    <div style={{
      position:"relative"
    }}>
    
      <div className="HR_MainHeaderSection HR_panel">
        <div className="HR_FlexCenterSpcBetween">
          <div className="HR_LogoSection">
            <a href="https://www.hr365.us" target="_blank" rel="noreferrer">
              <img src={Logo} alt="HR365" />
              {""}
            </a>
            

<div>
<span className="MBHAMBURGER" onClick={() => setHam(!ham)}><RxHamburgerMenu /></span>
{/* <span className="MBHAMBURGER" onClick={() => setHam(!ham)}> ≡</span> */}

</div>
          </div>

          <div className="HR_FlexCenterSpcBetween" style={{ width: "23%" }}>
            {/* <div className="HR_navMenu">
              <ul>
                <li>
                  <a href="#client" >Clients</a>
                </li>
                <li>
                  <a href="#pricing">Pricing</a>
                </li>
                <li>
                  <a href="#integration">Integration</a>
                </li>
              </ul>
            </div> */}
            <div className="MB_button">
              <DemoButtons />
            </div>
          </div>
        </div>
      </div>



      {
        ham ? <div className="MBHB" style={{position:"fixed" , top:"0vw", paddingTop: '1vw', background:"var(--panelBgColor)", height:"100vh"}}>
          <div className="mbflex">
            <div className="HR_LogoSection">
              <a href="https://www.hr365.us" target="_blank" rel="noreferrer">
                <img src={Logo} alt="HR365" />
                {""}
              </a>
            </div>{
              ham ? <div onClick={() => setHam(!ham)} style={{margin:'12px 0px'}}>
                <span className="cross"><RxCross1/></span>
              </div> : <div onClick={() => setHam(!ham)}>
                <span className="cross">+</span>
              </div>
            }


          </div>
          <div>
            <ul className={`${ham ? "mbmenu" : ""}`}>
              <li>
                <a href="#client" onClick={()=>setHam(false)}>Clients</a>
              </li>
              <li>
                <a href="#pricing" onClick={()=>setHam(false)}>Pricing</a>
              </li>
              <li>
                <a href="#integration" onClick={()=>setHam(false)}>Integration</a>
              </li>
            </ul>
          </div>
        </div> : ""
      }

      

    </div>
  );
}
export default Header;
